import React, { useEffect, useState } from "react";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Leftarrow from '../../images/leftarrow-white.svg';
import { Cookies } from 'react-cookie'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import  secureLocalStorage  from  "react-secure-storage";
import ExpiredStorage from "expired-storage";

export default function Ground() {
    const cookies = new Cookies();
    const [count, setCount] = useState(0);
    const [refreshData, setRefreshData] = useState(false);
    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);

    const onLoadModal = () => setLoad(true);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => {setOpen(false);
        setRefreshData(false)
        setCount(0);
        setSeatno('');

    }

    const [current, setCurrent] = useState('');
    const [getid, setid] = useState('');
    const [seatNo, setid1] = useState('');
    const [getSeatno, setSeatno] = useState('');
    let UserId = cookies.get('id');


    let place_id = localStorage.getItem('place_id');
    let dome_list_id = localStorage.getItem('dome_list_id');
    let event_name = localStorage.getItem('event_name');
    let event_date = localStorage.getItem('event_date');
    let place_name = localStorage.getItem('place_name');
    let event_id = localStorage.getItem('events_id');




    const [seat_infos, setdata] = useState();
    const headers = {
        'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',


    }
    const getdomedetails = () => {
        axios.get(`https://backoffice.theticketcompany.com.au/api/getdomedetails?placeId=${place_id}&eventId=${event_id}`,{headers: headers}).then((result) => {

            console.log(result.data.response_data.seat_infos);
            setdata(result.data.response_data.seat_infos);

        }).catch((error) => {
            console.log(error);
        });

    };

    useEffect(() => {
        getdomedetails();
    }, [refreshData]);


    const [qrCodeVisible, setQrCodeVisible] = useState(false);
    const navigate = useNavigate();

    const clickButton = () => {

        // Page position scroll code start
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        // Page position scroll code end



        localStorage.setItem("AdCount", count);
        localStorage.setItem("qrCodeVisible", qrCodeVisible);
        localStorage.setItem("getid", getid);
        localStorage.setItem("seatname1", seatNo);
        localStorage.setItem("amount", totalamt);
        seatNo.length = 1 ;
        console.log(seatNo)

        const body = new FormData();


        body.append("dome_price_id", getid);
        body.append("in_progress", "1");
        body.append("event_id", event_id);
        body.append("place_id", place_id);
        body.append("user_id", UserId);
        body.append("dome_list_id", seatNo);




        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',

        }
        if (UserId) {
        axios.post('https://backoffice.theticketcompany.com.au/api/booking-ticket', body, {
            headers: headers
        })
            .then((res) => {
                console.log(res.data.message);
                if (getid) {


                    console.log(res.data.status);
                    if(res.data.status == false){
                        onOpenModal()

                    }else{
                    const value =[res.data.ticketBooking_id]
                    console.log(value);
                    const expiredStorage = new ExpiredStorage();
                    expiredStorage.setItem("test", "foobar", 900);
                        localStorage.setItem("ticketBooking_id", value);

                        if(value !== ""){
                        if (count >= 1) {
                            navigate('/partyplotuser');
                        }
                    }
                }
                }

            }).catch((error) => {
                console.log(error)
                onOpenModal()
            });

        }
        else {

            navigate('/login');
        }

    }


    function handlecount(e) {
        const val = e.target.value;
        const title = e.target.id;
        const getstatedata = seat_infos.find(seat => seat.title === title).bookableSeat;
        const gettitle = seat_infos.find(seat => seat.title === title).info;
        const cols = gettitle.filter((seat) => seat.seat_status == "bookable");

    }
    const [lcount, setLcount] = useState(20);
    const [price, setPrice] = useState("");

    function increment(e) {

        const clickid = e.target.value;
        const getstatedata = seat_infos.find(seat => seat.title === clickid).bookableSeat;
        const gettitle = seat_infos.find(seat => seat.title === clickid).title;
        const info = seat_infos.find(seat => seat.title === clickid).info;
        const cols = info.filter((seat) => seat.booking_status === null);
        console.log(cols)

        if(getstatedata < 20){
        setLcount(getstatedata)
        }
        else{
        setLcount(20)
        }

        const getinfo = [];
        const getinfo1 = [];
        const seat_number = [];


        setCount(function (prevCount) {
            if (prevCount < lcount) {
                return (prevCount += 1);
            } else {
                return (prevCount = lcount);
            }
        });

        for (let i = 0; i <= count; i++) {
            getinfo.push(cols[i].dome_price_id,);
            getinfo1.push(cols[i].dome_list_id,);
            seat_number.push(cols[i].seat_number,);
            setPrice(cols[i].seat_price)
        }

        setid(getinfo);
        console.log(getinfo);
        setid1(getinfo1);
        setSeatno(seat_number);
    }
    console.log(price);
    const totalamt = price*count;
    cookies.set("amount", totalamt);


    console.log(totalamt);

    function decrement(e) {
        const clickid = e.target.value;

        const getstatedata = seat_infos.find(seat => seat.title === clickid).bookableSeat;
        const gettitle = seat_infos.find(seat => seat.title === clickid).title;

        const info = seat_infos.find(seat => seat.title === clickid).info;
        const cols = info.filter((seat) => seat.booking_status === null);
        const getinfo = [];
        const getinfo1 = [];
        const seat_number = [];

        setCount(function (prevCount) {
            if (prevCount > 0) {
                return (prevCount -= 1);
            } else {
                return (prevCount = 0);
            }
        });
        for (let i = count - 2; i > -1; i--) {
            getinfo.push(cols[i].dome_price_id,);

            seat_number.push(cols[i].seat_number,);

        }
        setid(getinfo);
        console.log(getinfo);
        setid1(getinfo1);
        setSeatno(seat_number);

    }

    const [button, setButton] = useState("Add");
    function test(e) {
        setRefreshData(true)
        const titles = e.target.value;
        const getstatedata = seat_infos.find(seat => seat.title === titles).bookableSeat;
        const gettitle = seat_infos.find(seat => seat.title === titles).title;
        const info = seat_infos.find(seat => seat.title === titles).info;
        const cols = info.filter((seat) => seat.seat_status == "bookable");
        if (cols == '') { console.log("no seat") }

        setCurrent(titles);
        setCount(0);
        setSeatno('');

    }
    const navigates = useNavigate();

    return (
        <>
            {seat_infos && <main>
                <Modal open={open} onClose={onCloseModal} showCloseIcon={false}  center>
                    <h3>Please Try Again</h3>

                    <br></br>
                    <button  onClick={onCloseModal} className="cancel">ok</button>
                    {/* <button onClick={onCloseModal} type="submit" className="no">No</button> */}
                </Modal>
                <div>
                    {/*start ticketinfo sec*/}
                    <section className="gt__user-sec ticketsec ticketsec2">
                        <div className="ticketinfo">
                        <img onClick={() => navigates(-1)} src={Leftarrow}></img>
                            <h2>{event_name}</h2>
                            <p className="venuedate venueinfo">{event_date}<span className="venueinfo">{place_name}</span></p>
                        </div>
                    </section>
                    {/*end ticketinfo sec*/}
                    {/*start category sec*/}
                    <section className="gt__user-sec gt__bg-image categorysec categorysec2 categorysec3">
                        <div className="categorybox">
                            <h2>SELECT YOUR CATEGORY</h2>
                            {seat_infos ? seat_infos.map((seat_infos, index) => {
                                const dome = seat_infos;
                                return (
                                    <>
                                        <div className="categoryinfo">
                                            <h3 key={index[0]}>{dome.title}
                                                <span>${seat_infos.info[0].seat_price}</span>
                                            </h3>

                                            <div className="addbtn" >
                                                {(() => {
                                                    const userdata = [];
                                                    if (dome.bookableSeat == " ") {
                                                        userdata.push(

                                                            <>
                                                                <p>

                                                                    <button style={{"color":"grey","border-color":"grey"}} value={dome.title}>Sold Out</button>


                                                                </p>
                                                            </>

                                                        );

                                                    }
                                                    else {
                                                        userdata.push(
                                                            <>
                                                                <p>
                                                                    {dome.title == current &&
                                                                        <>
                                                                            <button className="minusbtn" value={seat_infos.title} onClick={(e) => (decrement(e))}></button>
                                                                            <p>  {count}</p>
                                                                            <button className="plusbtn" value={seat_infos.title} onClick={(e) => (increment(e))}></button>
                                                                        </>
                                                                        || <button onClick={(e) => (test(e))} value={dome.title}>Add</button>

                                                                    }
                                                                </p>
                                                            </>
                                                        );
                                                    }
                                                    return userdata;
                                                })()}

                                            </div>
                                        </div>
                                    </>
                                )
                            }) : <h3>Data Not Found</h3>}
                        </div>
                    </section>
                    {/*end category sec*/}
                    {/*start proceed sec*/}
                    <section className="proceedbtnsec proceedbtnsec2 gt__user-sec2 bottomfixd2">
                        <div className="proceedbtn proceedbtn2">

                            <h3> AUD {totalamt}<span>{count} Ticket </span>

                            </h3>

                            <button onClick={clickButton} >Proceed</button>
                        </div>
                    </section>
                    {/*end proceed sec*/}
                </div>
            </main> || <h3><div className="loader"></div></h3>}
        </>
    )

}