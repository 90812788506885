// ** create-user.component.js ** //
import React, { Component, Redirect } from 'react';
import axios from 'axios';
import { Navigate, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import UserSignin from '../images/user-signin.svg';
import SigninGoogle from"../images/signingoogle.svg";
import SigninFb from "../images/signinfb.svg";


export default class CreateUser extends Component {
    constructor(props) {
        super(props)
        this.onChangefirstname = this.onChangefirstname.bind(this);
        this.onChangelastname = this.onChangelastname.bind(this);
        this.onChangeUsermobile = this.onChangeUsermobile.bind(this);
        this.onChangeUserEmail = this.onChangeUserEmail.bind(this);
        this.onChangeUserPassword = this.onChangeUserPassword.bind(this);
        this.onChangeUsercPassword = this.onChangeUsercPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            cpassword: '',
            errors: {}
        }
    }
    onChangefirstname(e) {
        this.setState({ firstname: e.target.value })
    }
    onChangelastname(e) {
        this.setState({ lastname: e.target.value })
    }
    onChangeUserEmail(e) {
        this.setState({ email: e.target.value })
    }
    onChangeUserPassword(e) {
        this.setState({ password: e.target.value })
    }
    onChangeUsercPassword(e) {
        this.setState({ cpassword: e.target.value })
    }
    onChangeUsermobile(e) {
        this.setState({ mobile: e.target.value })
    }



    onSubmit(e) {
        e.preventDefault()
        if (this.validateForm()) {

            const userObject = {
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                mobile: this.state.mobile,
                email: this.state.email,
                password: this.state.password
            };
            // alert(userObject);
            // console.log(userObject);
            const headers = {
                'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',
            }


            axios.post('https://backoffice.theticketcompany.com.au/api/register', userObject, {
                headers: headers
            })
                .then((res) => {

                    //  console.log(res.data);
                    if (res.status !== 200) {
                        if(res.data.status == true){
                            window.location = "/login"

                        }
                        else{
                            this.setState({error: res.data.message.email});

                            this.setState({errorphone: res.data.message.mobile});
                        }




                    }
                        // handle successful registration





                    // alert(res.data)

                }).catch((error) => {
                    // console.log(error)
                });
            this.setState({ firstname: '', lastname: '', mobile: '', email: '', password: '' })
        }
    }
    validateForm() {

        let errors = {};
        let formIsValid = true;

        if (!this.state.firstname) {
            formIsValid = false;
            errors["firstname"] = "*Please enter your firstname.";
        }

        if (typeof this.state.firstname !== "undefined") {
            if (!this.state.firstname.match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["firstname"] = "*Please enter alphabet characters only.";
            }
        }
        if (!this.state.lastname) {
            formIsValid = false;
            errors["lastname"] = "*Please enter your lastname.";
        }

        if (typeof this.state.lastname !== "undefined") {
            if (!this.state.lastname.match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["lastname"] = "*Please enter alphabet characters only.";
            }
        }

        if (!this.state.mobile) {
            formIsValid = false;
            errors["mobile"] = "Please enter your Mobile Number.";
        }

        if (typeof this.state.mobile !== "undefined") {

            var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
            if (!pattern.test(this.state.mobile)) {
                formIsValid = false;
                errors["mobile"] = "Please Enter Number Only";
            } else if (this.state.mobile.length != 10) {
                formIsValid = false;
                errors["mobile"] = "Please enter valid  Mobile Number.";
            }
        }
        // if (this.state.mobile !== undefined && this.state.mobile !== null) {
        //     formIsValid = false;
        //     errors["mobile"] = "*mobile already exit.";

        // }

        if (!this.state.email) {
            formIsValid = false;
            errors["email"] = "*Please enter your email-ID.";

        }
        // if (this.state.email !== undefined && this.state.email !== null) {
        //     formIsValid = false;
        //     errors["email"] = "*Email already exit.";

        // }

        if (typeof this.state.email !== "undefined") {

            //regular expression for email validation

            var pattern = new
                RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(this.state.email)) {
                formIsValid = false;
                errors["email"] = "*Please enter valid email-ID.";

            }
        }

        if (!this.state.password) {

            formIsValid = false;

            errors["password"] = "*Please enter your password.";

        }
        if (this.state.password !== this.state.cpassword ) {

            formIsValid = false;

            errors["cpassword"] = "*Passwords does not match.";

        }

        if (typeof this.state.password !== "undefined") {

            if (!this.state.password.match(/(?=.{8,})/)) {

                formIsValid = false;

                errors["password"] = "*Minimum length should be 8.";

            }

        }

        this.setState({

            errors: errors

        });

        return formIsValid;

    }

    render() {
        const { error } = this.state;
        const { errorphone } = this.state;
        return (
            <>
                <main>
                    {/*start SIGN IN sec*/}
                    <section className="gt__signup-sec gt__user-sec gt__bg-image gt__bg-image1 gt__signin-sec1">
                        <h1 className="txt-center">SIGN UP</h1>

                        <div className="gt__signin-wrap gt__user-wrap">
                            <div className="gt__user-icn">
                                <img src={UserSignin} alt="user" width={40} height={40} />
                            </div>
                            <div className="gt__signin-inner gt__user-inner">
                                {/* <div className="gt__login-googlefb d-flex d-flex-wrap justify-content-center align-items-center">
                                    <div className="gt__google-block">
                                        <a href="#">
                                            <img src={SigninGoogle} alt="Sign in with google" width={17} height={17} />
                                            <span>Sign in with google</span>
                                        </a>
                                    </div>
                                    <div className="gt__facebook-block">
                                        <a href="#">
                                            <img src={SigninFb} alt="Sign in with facebook" width={20} height={20} />
                                            <span>Sign in with facebook</span>
                                        </a>
                                    </div>
                                </div> */}
                                <div className="gt__form-block">
                                    <h4 className="gt__user-title txt-center"><span> sign in with email</span></h4>
                                    <form  method="post" name="userRegistrationForm" onSubmit={this.onSubmit}>
                                        <div className="gt__form-grp gt__uname d-flex d-flex-wrap justify-content-between align-items-center">
                                            <div className="gt__fname-block">
                                                <label htmlFor="fname">First Name</label>
                                                <input type="text" name="firstname" value={this.state.firstname} onChange={this.onChangefirstname} />
                                                <div className="errorMsg invalid-feedback">{this.state.errors.firstname}</div>
                                            </div>

                                            <div className="gt__fname-block">
                                                <label htmlFor="lname">Last Name</label>
                                                <input type="text" name="lastname" value={this.state.lastname} onChange={this.onChangelastname} />
                                                <div className="errorMsg invalid-feedback">{this.state.errors.lastname}</div>
                                            </div>

                                        </div>
                                        <div className="gt__form-grp">
                                            <label htmlFor="mobile">Phone Number</label>
                                            <input type="text" name="mobile" value={this.state.mobile} onChange={this.onChangeUsermobile} />
                                            <div className="errorMsg invalid-feedback">{this.state.errors.mobile}{errorphone} </div>
                                        </div>
                                        <div className="gt__form-grp">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" name="email" value={this.state.email} onChange={this.onChangeUserEmail} />
                                            <div className="errorMsg invalid-feedback">{this.state.errors.email}{error}</div>
                                        </div>

                                        <div className="gt__form-grp gt__form-pass">
                                            <label htmlFor="psw">Password</label>
                                            <input type="password" name="password" value={this.state.password} onChange={this.onChangeUserPassword} />
                                            <div className="errorMsg invalid-feedback">{this.state.errors.password}</div>
                                        </div>
                                        <div className="gt__form-grp gt__form-pass">
                                            <label htmlFor="psw">Confirm Password</label>
                                            <input type="password" name="cpassword" value={this.state.cpassword} onChange={this.onChangeUsercPassword} />
                                            <div className="errorMsg invalid-feedback">{this.state.errors.cpassword}</div>
                                        </div>

                                        <div className="gt__form-grp">
                                            <div className="gt__check-box">
                                                <label className="containerc">
                                                    <input type="checkbox" defaultChecked="checked" />
                                                    <span className="checkmark" />I accept the <a href="#">terms of use</a> &amp; <a href="#">privacy policy</a>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="gt__form-grp gt__form-btn txt-center">
                                            <button type="submit" className="gt__btn">Registration</button>
                                        </div>
                                        <div className="gt__form-grp gt__reg-link txt-center">
                                            <p>Have a already account? <NavLink to="/login"> Sign in here </NavLink></p>
                                        </div>
                                    </form></div>
                            </div>
                        </div>
                    </section>
                    {/*end SIGN IN sec*/}
                </main>
            </>
        )
    }
}