import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import secureLocalStorage from "react-secure-storage";

export default function Dome() {
  let place_id = localStorage.getItem('place_id');
  let event_name = localStorage.getItem('event_name');
  let event_date = localStorage.getItem('event_date');
  let place_name = localStorage.getItem('place_name');
  let event_id = localStorage.getItem('events_id');
  const [seat_infos, setData] = useState();
  const [current, setCurrent] = useState();
  const [checksys, setchecksys] = useState("Book Now");

  // Model Popup
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
  }

  const navigate = useNavigate();
  const handleClick = event => {
    // console.log(event.target.getAttribute('name'));
    const dome = event.target.getAttribute('name');
    const getstatedata = seat_infos.find(seat => seat.title.toLowerCase() == dome.toLowerCase()).bookableSeat;
    // console.log(getstatedata);

    if (getstatedata == 0) {
      setOpen(true)
    }
    else {
      localStorage.setItem("dome_id", dome);
      navigate('/Plenary-dome');
    }

  };

  const headers = {
    'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',
}
  const getdomedetails = () => {
    axios.get(`https://backoffice.theticketcompany.com.au/api/getdomedetails?placeId=${place_id}&eventId=${event_id}`,{
      headers: headers
    }).then((result) => {
      // console.log(result.data.response_data.seat_infos);
      setData(result.data.response_data.seat_infos);
      setCurrent(result.data.response_data.seat_infos[0].title);

    }).catch((error) => {
      // console.log(error);
    });

  };
  useEffect(() => {
    getdomedetails();
  }, [])

  // Table view code start
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const handleToggle1 = () => {
    setShow(!show); setShow1(false); setShow2(false); setShow3(false); setShow4(false); setShow5(false);
    setShow6(false);
  }

  const handleToggle2 = () => {
    setShow1(!show1); setShow(false); setShow2(false); setShow3(false); setShow4(false); setShow5(false);
    setShow6(false);
  }
  const handleToggle3 = () => {
    setShow2(!show2); setShow(false); setShow1(false); setShow3(false); setShow4(false); setShow5(false);
    setShow6(false);
  }
  const handleToggle4 = () => {
    setShow3(!show3); setShow(false); setShow2(false); setShow1(false); setShow4(false); setShow5(false);
    setShow6(false);
  }
  const handleToggle5 = () => {
    setShow4(!show4); setShow(false); setShow2(false); setShow3(false); setShow1(false); setShow5(false);
    setShow6(false);
  }
  const handleToggle6 = () => {
    setShow5(!show5); setShow(false); setShow2(false); setShow3(false); setShow4(false); setShow1(false);
    setShow6(false);
  }
  const handleToggle7 = () => {
    setShow6(!show6); setShow(false); setShow2(false); setShow3(false); setShow4(false); setShow5(false);
    setShow1(false);
  }

  // Table view code end

  return (
    <>
      <Modal open={open} showCloseIcon={false} center>
        <h3 className='htext' style={{ "text-align": "center" }}>Sold out !</h3>
        <br></br>
        <button onClick={onCloseModal} style={{ "margin": "inherit" }} type="submit" className="ok">ok</button>
      </Modal>

      <div className="share_offer box_split_section ng-scope" ng-if="event.svgadvancelayout !=''" style={{ marginTop: '20px' }}>
        <div ng-bind-html="event.svgadvancelayout | trust" className="ng-binding">{/*?xml version="1.0" encoding="UTF-8"?*/}
          <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1123 607">
            <defs>
              <style dangerouslySetInnerHTML={{ __html: "svg, path, tspan, text{font-family:Muli, sans-serif !important;font-weight:bold;}path{cursor:pointer;}.cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 {  fill: #fff;}.cls-7 {  letter-spacing: -.11em;}.cls-8 {  fill: #fad316;}.cls-9 {  fill: #dbdbdb;}.cls-10 {  letter-spacing: -.01em;}.cls-10, .cls-11, .cls-12 {  font-family: Gotham-Book, Gotham;}.cls-13 {  font-family: Gotham-Bold, Gotham;}.cls-13, .cls-3, .cls-14, .cls-15 {  font-weight: 700;}.cls-16 {  fill: #cd6162;}.cls-11, .cls-2 {  font-size: 12px;}.cls-17 {  fill: #f94158;}.cls-18, .cls-6 {  font-size: 13px;}.cls-19 {  letter-spacing: 0em;}.cls-20 {  fill: #44a3f2;}.cls-21 {  letter-spacing: -.09em;}.cls-22 {  fill: #4ed014;}.cls-3 {  font-family: ProximaNova-Bold, 'Proxima Nova';  font-size: 18.85px;}.cls-14 {  font-size: 11.64px;}.cls-14, .cls-15 {  font-family: Muli-ExtraBold, Muli;}.cls-23 {  fill: #9845ef;}.cls-15 {  font-size: 12.8px;}.cls-4, .cls-24 {  font-size: 16.57px;}.cls-5 {  isolation: isolate;  opacity: 0;}" }} />
            </defs>
            <g id="Layer_1-2" data-name="Layer 1">
              <rect className="cls-5" width={1123} height={607} />
              <image width={2523} height={1129} transform="translate(0 49.66) scale(.45)" xlinkHref="https://www.premiertickets.co/assets/uploads/2022/11/seat_plan_mel.png" />
              <path name="DIAMONDB" onClick={handleClick} data-target="#singleticketseating4943" className="cls-22" d="M642.29,102.71c-4.92,38.22-9.83,76.45-14.73,114.55h-130.8c-5.32-37.74-10.58-75.16-15.91-113,4.88,0,9.59,.04,14.31,0,39.21-.4,78.42-.81,117.63-1.24,.9,0,1.79-.2,2.69-.3,8.94,0,17.88,0,26.81,0Z" />
              <path name="DIAMONDA" onClick={handleClick} data-target="#singleticketseating4942" className="cls-22" d="M654.88,102.71c6.39,2,12.76,4.08,19.19,5.98,36.74,10.86,73.49,21.68,110.23,32.52,.95,.28,1.87,.63,2.96,1.01-14.42,36.14-28.77,72.11-43.19,108.24-35.48-10.06-70.73-20.06-106.15-30.1,5.3-39.34,10.59-78.49,15.87-117.65,.36,0,.73,0,1.09,0Z" />
              <path d="M451.86,536.2v-25.59h16.23c0-1.18,0-2.14,0-3.1,0-4.68,0-4.71,4.32-6.41,21.41-8.45,43.69-13.28,66.64-15.33,34.2-3.06,67.5,.93,99.87,12.14,3.68,1.28,7.27,2.82,10.96,4.08,1.76,.6,2.56,1.45,2.34,3.34-.19,1.6-.04,3.24-.04,5.09h15.64v25.79h-215.96Z" />
              <path name="BRONZEC" onClick={handleClick} data-target="#singleticketseating4955" className="cls-16" d="M1063.65,403.68c-29.43,12.17-58.86,24.35-88.64,36.66-11.83-24.19-23.64-48.35-35.58-72.77,26.01-18.37,51.96-36.71,78.13-55.19,.57,1.01,1.13,1.91,1.61,2.85,14.28,28.38,28.55,56.76,42.84,85.14,.48,.95,1.1,1.84,1.65,2.76v.55Z" />
              <path name="BRONZED" onClick={handleClick} className="cls-16" d="M59.5,403.13c.55-.92,1.17-1.81,1.64-2.77,14.1-28.18,28.19-56.37,42.28-84.56,.44-.89,.93-1.75,1.56-2.92,26.09,18.24,52.03,36.38,78.19,54.67-11.69,23.37-23.31,46.62-35.02,70.04-29.69-11.36-59.17-22.64-88.65-33.93,0-.18,0-.36,0-.55Z" />
              <path name="VIPB" onClick={handleClick} data-target="#singleticketseating4937" className="cls-17" d="M684.42,412.04c-10.62,26.07-20.98,51.51-31.46,77.23-1.78-.76-3.32-1.41-4.97-2.11-.48,1.74-.91,3.31-1.4,5.08-1.3-.49-2.42-1.08-3.61-1.34-2.93-.65-4.46-2.22-4.67-5.35-.06-.83-1.49-2.03-2.48-2.27-10.52-2.54-21.01-5.38-31.65-7.24-18.1-3.17-36.41-3.96-54.78-2.88-16.51,.97-32.76,3.46-48.94,7.82,.68,3.08-1.5,3.55-3.64,4.25-6.38,2.08-12.72,4.27-19.27,6.49-1.6-3.98-3.13-7.75-4.75-11.78-2.13,.51-4.21,1.01-6.58,1.58-8.91-23.38-17.81-46.72-26.98-70.77,81.94-27.69,163.47-31.16,245.18,1.3Z" />
              <path name="DIAMONDC" onClick={handleClick} data-target="#singleticketseating4944" className="cls-22" d="M379.08,249.91c-14.05-35.75-28.02-71.31-42.14-107.25,44.48-11.85,88.85-23.67,133.52-35.57,5.29,37.75,10.56,75.34,15.88,113.27-35.66,9.83-71.26,19.64-107.25,29.56Z" />
              <path name="SILVERC" onClick={handleClick} data-target="#singleticketseating4951" className="cls-9" d="M935.48,359.19c-26.28-25.73-52.27-51.16-78.36-76.7,19.54-26.18,38.77-51.93,58.22-77.99,32.14,33.05,64.08,65.89,96.31,99.03-25.73,18.8-51.3,37.48-76.18,55.66Z" />
              <path name="SILVERD" onClick={handleClick} data-target="#singleticketseating4952" className="cls-9" d="M188.12,357.9c-26.07-18.23-52.34-36.61-79.01-55.26,32.96-32.6,65.66-64.95,98.67-97.6,18.81,25.92,37.5,51.69,56,77.19-25.23,25.23-50.34,50.34-75.67,75.67Z" />
              <path name="SILVERB" onClick={handleClick} data-target="#singleticketseating4950" className="cls-9" d="M206.45,372c23.71-24.26,47.21-48.31,71.08-72.72,26.42,36.58,52.42,72.57,78.69,108.95-14.21,13.66-28.38,27.28-42.82,41.16-35.19-25.46-71.32-51.61-106.95-77.39Z" />
              <path name="SILVERA" onClick={handleClick} data-target="#singleticketseating4949" className="cls-9" d="M845.31,301.35c24.15,23.79,48.03,47.31,72.22,71.14-36.14,26.1-72.08,52.06-108.33,78.24-13.98-14.33-27.96-28.66-42.15-43.21,26.03-35.31,52.01-70.56,78.26-106.17Z" />
              <path name="PLATINUMB" onClick={handleClick} data-target="#singleticketseating4940" className="cls-20" d="M619.25,277.73c-4.16,31.08-8.32,62.23-12.48,93.3h-89.76c-4.22-31.15-8.39-61.99-12.63-93.3h114.87Z" />
              <path name="PLATINUMC" onClick={handleClick} data-target="#singleticketseating4941" className="cls-20" d="M403.15,265.24c28.51-7.86,56.75-15.65,85.36-23.53,5.84,43.97,11.66,87.8,17.52,131.87-18.06,5.11-35.98,10.18-54.17,15.33-16.21-41.17-32.38-82.22-48.71-123.67Z" />
              <path name="PLATINUMA" onClick={handleClick} data-target="#singleticketseating4939" className="cls-20" d="M719.99,265.79c-16.27,41.13-32.43,81.99-48.7,123.12-17.92-5.12-35.72-10.21-53.63-15.32,6.03-44.03,12.02-87.79,18.06-131.87,28.18,8.05,56.12,16.04,84.27,24.08Z" />
              <path name="GOLDC" onClick={handleClick} data-target="#singleticketseating4947" className="cls-8" d="M847.5,277.27c-18.88-9.71-37.55-19.31-56.35-28.98-1.68,1.84-3.26,3.57-4.96,5.43-3.24-1.61-6.39-3.18-9.83-4.9,13.12-32.26,26.2-64.41,39.39-96.85,29.79,15.72,59.37,31.32,89.19,47.06-19.2,26.15-38.22,52.05-57.45,78.25Z" />
              <path name="GOLDD" onClick={handleClick} data-target="#singleticketseating4948" className="cls-8" d="M307.93,153.06c13.16,32.17,26.22,64.07,39.4,96.3-3.04,1.61-6.05,3.21-9.23,4.9-1.48-1.96-2.9-3.83-4.49-5.94-19.27,9.63-38.52,19.24-57.96,28.96-18.58-25.51-37.08-50.9-55.81-76.6,29.33-15.85,58.53-31.63,88.1-47.61Z" />
              <path name="BRONZEB" onClick={handleClick} data-target="#singleticketseating4954" className="cls-16" d="M201.23,381.8c36.28,26.2,72.18,52.13,108.34,78.24-5.82,11.47-11.61,22.87-17.5,34.48-41.62-16.43-83.06-32.79-124.76-49.25,11.35-21.23,22.57-42.22,33.93-63.47Z" />
              <path name="BRONZEA" onClick={handleClick} data-target="#singleticketseating4953" className="cls-16" d="M955.85,446.36c-41.55,16.11-82.76,32.08-124.21,48.15-5.85-11.33-11.63-22.53-17.52-33.92,36.1-26.07,72.06-52.04,108.34-78.25,11.12,21.33,22.18,42.52,33.39,64.02Z" />
              <path name="GOLDB" onClick={handleClick} data-target="#singleticketseating4946" className="cls-8" d="M351.73,285.52c12.62,32.01,25.13,63.73,37.74,95.72-9.5,4.75-18.89,9.45-28.45,14.24-24.61-33.91-49.11-67.66-73.87-101.77,15.3-7.47,30.46-14.88,45.93-22.44,3.29,5.64,6.57,11.25,9.88,16.92,2.97-.9,5.73-1.74,8.77-2.66Z" />
              <path name="GOLDA" onClick={handleClick} data-target="#singleticketseating4945" className="cls-8" d="M734.77,381.24c11.54-29.3,22.97-58.32,34.46-87.51,2.81,1.09,5.4,2.1,8.23,3.2,3.51-6.25,6.97-12.42,10.44-18.59q3.21-5.72,8.96-2.81c12.02,6.08,24.05,12.15,36.07,18.24,.96,.48,1.89,1.02,3.02,1.64-24.1,33.42-48.07,66.65-72.17,100.06-9.66-4.74-19.19-9.41-29.01-14.22Z" />
              <path name="VIPC" onClick={handleClick} data-target="#singleticketseating4938" className="cls-17" d="M431.5,413.06c.45,.93,.83,1.64,1.13,2.38,10.26,24.8,20.48,49.61,30.82,74.37,.82,1.97,.58,2.94-1.1,4.23-8.59,6.59-17.08,13.32-25.82,20.17-20.56-20.91-41.04-41.75-61.84-62.91,18.99-12.79,37.82-25.46,56.8-38.24Z" />
              <path name="VIPA" onClick={handleClick} data-target="#singleticketseating4936" className="cls-17" d="M687.08,512.85c-8.57-5.98-18.05-12.6-27.82-19.42,10.92-26.76,21.83-53.49,32.83-80.44,19.06,13.2,37.84,26.2,57.03,39.48-20.71,20.16-41.2,40.09-62.04,60.38Z" />
              <path name="VVIP" onClick={handleClick} data-target="#singleticketseating4935" className="cls-23" d="M453.51,449.02c0-.36,0-.73,0-1.09,.53-.07,1.09-.05,1.59-.21,27.04-8.57,54.81-13.19,83.05-15.21,4.26-.3,8.51-.66,12.77-1,9.49,0,18.97,0,28.46,0,1.16,.15,2.31,.4,3.48,.45,20.66,.91,41.11,3.53,61.26,8.18,8.75,2.02,17.4,4.45,26.1,6.69v1.09c-.37,.69-.81,1.36-1.11,2.09-5.08,12.21-10.15,24.43-15.22,36.65-.33,.8-.95,1.94-1.37,2.85-1.67-.75-2.92-1.76-4.54-2.48-.47,1.9-.88,3.57-1.28,5.23h-.55c-1.74-.75-3.96-.87-5.63-1.75-.78-.41-2.49-1.79-2.47-2.41,.1-4.11-1.7-3.91-4.82-4.79-37.36-10.59-75.17-13.4-113.38-6.74-6.51,1.14-12.85,3.54-19.32,4.94,.15,.22,.21-.35,.36-.13-.57,.98,.07,3.56-.76,3.86-7.07,2.57-15.23,3.92-22.55,6.4-1.63-4.05-3.18-7.89-4.78-11.83-2.3,.58-4.37,1.11-6.55,1.66-2.67-6.97-5.21-13.68-7.82-20.37-1.58-4.04-3.27-8.05-4.9-12.07Z" />
              <text className="cls-6" transform="translate(419.76 467.03) scale(1.02 1)"><tspan className="cls-12"><tspan x="-10.92" y={0}>VIP-C</tspan></tspan><tspan className="cls-13"><tspan x="-5.92" y="15.6"></tspan></tspan></text>
              <text className="cls-4" transform="translate(515.49 458.79) scale(1.02 1)"><tspan className="cls-10" x={0} y={0}>V</tspan><tspan className="cls-12" x="12.26" y={0}>VIP </tspan><tspan className="cls-13" x="45.54" y={0}></tspan></text>
              <text className="cls-3" transform="translate(530.76 519.07)"><tspan x={0} y={0}>STAGE</tspan></text>
              <text className="cls-6" transform="translate(682.14 467.03) scale(1.02 1)"><tspan className="cls-12"><tspan x={0} y={0}>VIP-A</tspan></tspan><tspan className="cls-13"><tspan x="-5.92" y="15.6"></tspan></tspan></text>
              <text className="cls-4" transform="translate(522 418.3) scale(1.02 1)"><tspan className="cls-12" x={0} y={0}>VIP-B </tspan><tspan className="cls-13" x="48.28" y={0}></tspan></text>
              <text className="cls-2" transform="translate(634.81 305.01) scale(1.02 1)"><tspan className="cls-12"><tspan x={0} y={0}>PL</tspan><tspan className="cls-21" x="15.44" y={0}>A</tspan><tspan x="23.84" y={0}>TINUM</tspan></tspan><tspan className="cls-13"><tspan x="17.71" y="14.4">-A</tspan></tspan><tspan className="cls-13"><tspan x="12.71" y="26.4"></tspan></tspan></text>
              <text className="cls-2" transform="translate(533.09 334.26) scale(1.02 1)"><tspan className="cls-12"><tspan x={0} y="-9">PL</tspan><tspan className="cls-21" x="15.44" y="-9">A</tspan><tspan x="23.84" y="-9">TINUM</tspan></tspan>
                <tspan className="cls-13"><tspan x="17.71" y="5.4">-B</tspan></tspan><tspan className="cls-13"><tspan x="13.9" y="26.4"></tspan></tspan></text>
              <text className="cls-2" transform="translate(424.09 302.27) scale(1.02 1)"><tspan className="cls-12"><tspan x={0} y={0}>PL</tspan><tspan className="cls-21" x="15.44" y={0}>A</tspan><tspan x="23.84" y={0}>TINUM</tspan></tspan>
                <tspan className="cls-13"><tspan x="17.71" y="14.4">-C</tspan></tspan><tspan className="cls-13"><tspan x="17.71" y="28.4"></tspan></tspan></text>

              <text className="cls-24" transform="translate(667.02 178.62) scale(1.02 1)"><tspan className="cls-12"><tspan x="-5" y={0}>DIAMOND-A</tspan></tspan><tspan className="cls-13"><tspan x="20.19" y="19.88"></tspan></tspan></text>
              <text className="cls-24" transform="translate(519.32 172.68) scale(1.02 1)"><tspan className="cls-12"><tspan x="-5" y={0}>DIAMOND-B</tspan></tspan><tspan className="cls-13"><tspan x="22.64" y="19.88"></tspan></tspan></text>
              <text className="cls-24" transform="translate(376.32 185.68) scale(1.02 1)"><tspan className="cls-12"><tspan x="-5" y={0}>DIAMOND-C</tspan></tspan><tspan className="cls-13"><tspan x="23.19" y="19.88"></tspan></tspan></text>
              <text className="cls-6" transform="translate(321.26 326.17) scale(1.02 1)"><tspan className="cls-12"><tspan x="-5" y={0}>GOLD</tspan></tspan><tspan className="cls-13"><tspan x="10.74" y="15.6">-B</tspan></tspan><tspan className="cls-13"><tspan x="12.74" y="28.6"></tspan></tspan></text>

              <text className="cls-6" transform="translate(267.49 219.17) scale(1.02 1)"><tspan className="cls-12"><tspan x="-8" y={0}>GOLD-D</tspan></tspan><tspan className="cls-13"><tspan x="6.92" y="15.6"></tspan></tspan></text>



              <text className="cls-6" transform="translate(760.49 331.17) scale(1.02 1)"><tspan className="cls-12"><tspan x={0} y={0}>GOLD</tspan></tspan><tspan className="cls-13"><tspan x="3.74" y="15.6">-A</tspan></tspan><tspan className="cls-13"><tspan x="-5.74" y="29.6"></tspan></tspan></text>
              <text className="cls-6" transform="translate(819.49 219.17) scale(1.02 1)"><tspan className="cls-12"><tspan x={0} y={0}>GOLD-C</tspan></tspan><tspan className="cls-13"><tspan x="6.92" y="15.6"></tspan></tspan></text>
              <text className="cls-18" transform="translate(259.91 381.17) scale(1.02 1)"><tspan className="cls-12"><tspan x={0} y={0}>SI</tspan><tspan className="cls-7" x="12.1" y={0}>L</tspan><tspan className="cls-19" x="18.65" y={0}>VER-B</tspan></tspan><tspan className="cls-13"><tspan x="10.66" y="15.6"></tspan></tspan></text>
              <text className="cls-18" transform="translate(169.91 290.17) scale(1.02 1)"><tspan className="cls-12"><tspan x={0} y={0}>SI</tspan><tspan className="cls-7" x="12.1" y={0}>L</tspan><tspan className="cls-19" x="18.65" y={0}>VER-D</tspan></tspan><tspan className="cls-13"><tspan x="10.73" y="15.6"></tspan></tspan></text>

              <text className="cls-18" transform="translate(822.91 372.17) scale(1.02 1)"><tspan className="cls-12"><tspan x={0} y={0}>SI</tspan><tspan className="cls-7" x="12.1" y={0}>L</tspan><tspan className="cls-19" x="18.65" y={0}>VER-A</tspan></tspan><tspan className="cls-13"><tspan x="10.66" y="15.6"></tspan></tspan></text>
              <text className="cls-18" transform="translate(904.91 288.17) scale(1.02 1)"><tspan className="cls-12"><tspan x={0} y={0}>SI</tspan><tspan className="cls-7" x="12.1" y={0}>L</tspan><tspan className="cls-19" x="18.65" y={0}>VER-C</tspan></tspan><tspan className="cls-13"><tspan x="10.73" y="15.6"></tspan></tspan></text>
              {/* <tspan x="10.73" y="15.6"></tspan>Price</tspan></text> */}
              <text className="cls-6" transform="translate(196.17 438.07) scale(1.02 1)"><tspan className="cls-12"><tspan x="-5" y={0}>BRONZE-B</tspan></tspan><tspan className="cls-13"><tspan x="16.11" y="15.6"></tspan></tspan></text>

              <text className="cls-6" transform="translate(92.17 387.07) scale(1.02 1)"><tspan className="cls-12"><tspan x={0} y={0}>BRONZE-D</tspan></tspan><tspan className="cls-13"><tspan x="16.11" y="15.6"></tspan></tspan></text>
              <text className="cls-6" transform="translate(875.17 434.07) scale(1.02 1)"><tspan className="cls-12"><tspan x="-5" y={0}>BRONZE-A</tspan></tspan><tspan className="cls-13"><tspan x="16.11" y="15.6"></tspan></tspan></text>
              <text className="cls-6" transform="translate(969.17 387.07) scale(1.02 1)"><tspan className="cls-12"><tspan x={0} y={0}>BRONZE-C</tspan></tspan><tspan className="cls-13"><tspan x="16.11" y="15.6"></tspan></tspan></text>

              <text className="cls-11" transform="translate(353.23 279.81) rotate(66.62) scale(1.02 1)"><tspan x={0} y={0}>DOOR 1</tspan></text>
              <text className="cls-11" transform="translate(390.65 271.46) rotate(66.62) scale(1.02 1)"><tspan x={0} y={0}>DOOR 2</tspan></text>
              <text />
              <text className="cls-11" transform="translate(720.28 276.18) rotate(113.32) scale(1.02 1)"><tspan x={0} y={0}>DOOR 3</tspan></text>
              <text className="cls-11" transform="translate(758.51 289.53) rotate(113.32) scale(1.02 1)"><tspan x={0} y={0}>DOOR 4</tspan></text>
            </g>
          </svg></div>
      </div>

      {/* Table view content start */}
      <div className="event_ticket_info box_split_section">
        <div id="EventTicketBlock" className="ng-scope">

          <div className="panel-group" id="accordion">
            <div className="ticketpanel ng-scope" id="panel_4935">
              <ul className="ticket_info_row ng-scope">
                <li className="ticket_category_n1 ng-binding">VVIP <span className="ticket_category_desc ng-binding" ></span>
                  <div>
                  </div></li>
                <li className="action_btn des2 ptkselectseat" onClick={() => { setShow(!show); handleToggle1() }}><span>{!show && "Select Seats"} {show && "Select Seats"}</span></li>
                {show &&
                  <div className="ticketpanel blockTicket" id="ticketBlock_413">
                    <ul className="ticket_info_row ng-scope">
                      <li className="ticket_category_n1 ng-binding">VVIP <span className="ticket_category_desc ng-binding" />  </li>
                      <li className="action_btn des2 ng-scope" >
                        <input type="button" name="VVIP" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" />
                      </li>
                    </ul>
                  </div>
                }
              </ul>
            </div><div className="ticketpanel ng-scope" id="panel_4937">
              <ul className="ticket_info_row ng-scope">
                <li className="ticket_category_n1 ng-binding">VIP <span className="ticket_category_desc ng-binding" > </span>
                  <div>
                  </div></li>
                <li className="action_btn des2 ptkselectseat" onClick={() => { setShow1(!show1); handleToggle2() }}><span>{!show1 && "Select Seats"} {show1 && "Select Seats"}</span></li>

                {show1 &&
                  <div className="ticketpanel blockTicket" id="ticketBlock_387">
                    <ul className="ticket_info_row ng-scope">
                      <li className="ticket_category_n1 ng-binding">VIP - A <span className="ticket_category_desc ng-binding" /></li>
                      <li className="action_btn des2 ng-scope">
                        <input type="button" name="VIPA" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" />
                      </li>
                    </ul><ul className="ticket_info_row ng-scope">
                      <li className="ticket_category_n1 ng-binding">VIP - B <span className="ticket_category_desc ng-binding" /></li>
                      <li className="action_btn des2 ng-scope">
                        <input type="button" name="VIPB" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" />
                      </li>
                    </ul><ul className="ticket_info_row ng-scope">
                      <li className="ticket_category_n1 ng-binding">VIP - C <span className="ticket_category_desc ng-binding" /></li>
                      <li className="action_btn des2 ng-scope">
                        <input type="button" name="VIPC" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" />
                      </li>
                    </ul>
                  </div>}

              </ul>
            </div>
            <div className="ticketpanel ng-scope" id="panel_4940">
              <ul className="ticket_info_row ng-scope" ng-if="ticket.et_blockid != '0' && ticket.blockstatus == '1'">
                <li className="ticket_category_n1 ng-binding">PLATINUM <span className="ticket_category_desc ng-binding" ></span>
                  <div>
                  </div></li>
                <li className="action_btn des2 ptkselectseat" onClick={() => { setShow2(!show2); handleToggle3() }}><span>{!show2 && "Select Seats"} {show2 && "Select Seats"}</span></li>

                {show2 &&
                  <div className="ticketpanel blockTicket" id="ticketBlock_389" >
                    <ul className="ticket_info_row ng-scope">
                      <li className="ticket_category_n1 ng-binding">PLATINUM - A <span className="ticket_category_desc ng-binding" /></li>
                      <li className="action_btn des2 ng-scope">
                        <input type="button" name="PLATINUMA" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4939" />
                      </li>
                    </ul><ul className="ticket_info_row ng-scope">
                      <li className="ticket_category_n1 ng-binding">PLATINUM - B <span className="ticket_category_desc ng-binding" /></li>
                      <li className="action_btn des2 ng-scope">
                        <input type="button" name="PLATINUMB" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4940" />
                      </li>
                    </ul><ul className="ticket_info_row ng-scope">
                      <li className="ticket_category_n1 ng-binding">PLATINUM - C <span className="ticket_category_desc ng-binding" /></li>
                      <li className="action_btn des2 ng-scope">
                        <input type="button" name="PLATINUMC" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4941" />
                      </li>
                    </ul>
                  </div>}
              </ul>
            </div>
            <div className="ticketpanel ng-scope" id="panel_4943">
              <ul className="ticket_info_row ng-scope" ng-if="ticket.et_blockid != '0' && ticket.blockstatus == '1'">
                <li className="ticket_category_n1 ng-binding">DIAMOND <span className="ticket_category_desc ng-binding" ></span>
                  <div> </div>
                </li>
                <li className="action_btn des2 ptkselectseat" onClick={() => { setShow3(!show3); handleToggle4() }}><span>{!show3 && "Select Seats"} {show3 && "Select Seats"}</span></li>

                {show3 &&
                  <div className="ticketpanel blockTicket" id="ticketBlock_391">
                    <ul className="ticket_info_row ng-scope">
                      <li className="ticket_category_n1 ng-binding">DIAMOND - A <span className="ticket_category_desc ng-binding" /></li>
                      <li className="action_btn des2 ng-scope">
                        <input type="button" name="DIAMONDA" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4942" />
                      </li>
                    </ul><ul className="ticket_info_row ng-scope">
                      <li className="ticket_category_n1 ng-binding">DIAMOND - B <span className="ticket_category_desc ng-binding" /></li>
                      <li className="action_btn des2 ng-scope">
                        <input type="button" name="DIAMONDB" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4943" />
                      </li>
                    </ul><ul className="ticket_info_row ng-scope">
                      <li className="ticket_category_n1 ng-binding">DIAMOND - C <span className="ticket_category_desc ng-binding" /></li>
                      <li className="action_btn des2 ng-scope">
                        <input type="button" name="DIAMONDC" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4944" />
                      </li>
                    </ul>
                  </div>
                }
              </ul>
            </div>
            <div className="ticketpanel ng-scope" id="panel_4945">
              <ul className="ticket_info_row ng-scope" ng-if="ticket.et_blockid != '0' && ticket.blockstatus == '1'">
                <li className="ticket_category_n1 ng-binding">GOLD <span className="ticket_category_desc ng-binding" ></span>
                  <div>
                  </div></li>
                <li className="action_btn des2 ptkselectseat" onClick={() => { setShow4(!show4); handleToggle5() }}><span>{!show4 && "Select Seats"} {show4 && "Select Seats"}</span></li>

                {show4 && <div className="ticketpanel blockTicket" id="ticketBlock_393">
                  <ul className="ticket_info_row ng-scope">
                    <li className="ticket_category_n1 ng-binding">GOLD - A <span className="ticket_category_desc ng-binding" /> </li>
                    <li className="action_btn des2 ng-scope">
                      <input type="button" name="GOLDA" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4945" />
                    </li>
                  </ul><ul className="ticket_info_row ng-scope">
                    <li className="ticket_category_n1 ng-binding">GOLD - B <span className="ticket_category_desc ng-binding" /></li>
                    <li className="action_btn des2 ng-scope">
                      <input type="button" name="GOLDB" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4946" />
                    </li>
                  </ul><ul className="ticket_info_row ng-scope">
                    <li className="ticket_category_n1 ng-binding">GOLD - C <span className="ticket_category_desc ng-binding" /></li>
                    <li className="action_btn des2 ng-scope">
                      <input type="button" name="GOLDC" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4947" />
                    </li>
                  </ul><ul className="ticket_info_row ng-scope">
                    <li className="ticket_category_n1 ng-binding">GOLD - D <span className="ticket_category_desc ng-binding" /></li>
                    <li className="action_btn des2 ng-scope">
                      <input type="button" name="GOLDD" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4948" />
                    </li>
                  </ul>
                </div>}

              </ul>
            </div>
            <div className="ticketpanel ng-scope" id="panel_4951">
              <ul className="ticket_info_row ng-scope" ng-if="ticket.et_blockid != '0' && ticket.blockstatus == '1'">
                <li className="ticket_category_n1 ng-binding" >SILVER <span className="ticket_category_desc ng-binding" ></span>
                  <div>
                  </div></li>
                <li className="action_btn des2 ptkselectseat" onClick={() => { setShow5(!show5); handleToggle6() }}><span>{!show5 && "Select Seats"} {show5 && "Select Seats"}</span></li>

                {show5 && <div className="ticketpanel blockTicket" id="ticketBlock_395">
                  <ul className="ticket_info_row ng-scope">
                    <li className="ticket_category_n1 ng-binding">SILVER - A <span className="ticket_category_desc ng-binding" /></li>
                    <li className="action_btn des2 ng-scope">
                      <input type="button" name="SILVERA" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4949" />
                    </li>
                  </ul><ul className="ticket_info_row ng-scope">
                    <li className="ticket_category_n1 ng-binding">SILVER - B <span className="ticket_category_desc ng-binding" /></li>
                    <li className="action_btn des2 ng-scope">
                      <input type="button" name="SILVERB" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4950" />
                    </li>
                  </ul><ul className="ticket_info_row ng-scope">
                    <li className="ticket_category_n1 ng-binding">SILVER - C <span className="ticket_category_desc ng-binding" /></li>
                    <li className="action_btn des2 ng-scope">
                      <input type="button" name="SILVERC" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4951" />
                    </li>
                  </ul><ul className="ticket_info_row ng-scope">
                    <li className="ticket_category_n1 ng-binding">SILVER - D <span className="ticket_category_desc ng-binding" /></li>
                    <li className="action_btn des2 ng-scope">
                      <input type="button" name="SILVERD" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4952" />
                    </li>
                  </ul>
                </div>}

              </ul>
            </div>
            <div className="ticketpanel ng-scope" id="panel_4953">
              <ul className="ticket_info_row ng-scope" ng-if="ticket.et_blockid != '0' && ticket.blockstatus == '1'">
                <li className="ticket_category_n1 ng-binding">BRONZE <span className="ticket_category_desc ng-binding" ></span>
                  <div>
                  </div></li>
                <li className="action_btn des2 ptkselectseat" onClick={() => { setShow6(!show6); handleToggle7() }}><span>{!show6 && "Select Seats"} {show6 && "Select Seats"}</span></li>

                {show6 && <div className="ticketpanel blockTicket" id="ticketBlock_397">
                  <ul className="ticket_info_row ng-scope">
                    <li className="ticket_category_n1 ng-binding">BRONZE - A <span className="ticket_category_desc ng-binding" /></li>
                    <li className="action_btn des2 ng-scope">
                      <input type="button" name="BRONZEA" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4953" />
                    </li>
                  </ul><ul className="ticket_info_row ng-scope">
                    <li className="ticket_category_n1 ng-binding">BRONZE - B <span className="ticket_category_desc ng-binding" /></li>
                    <li className="action_btn des2 ng-scope">
                      <input type="button" name="BRONZEB" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4954" />
                    </li>
                  </ul><ul className="ticket_info_row ng-scope">
                    <li className="ticket_category_n1 ng-binding">BRONZE - C <span className="ticket_category_desc ng-binding" /></li>
                    <li className="action_btn des2 ng-scope">
                      <input type="button" name="BRONZEC" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" data-target="#singleticketseating4955" />
                    </li>
                  </ul><ul className="ticket_info_row ng-scope">
                    <li className="ticket_category_n1 ng-binding">BRONZE - D <span className="ticket_category_desc ng-binding" /></li>
                    <li className="action_btn des2 ng-scope">
                      <input type="button" name="BRONZED" onClick={handleClick} defaultValue="Book Now" className="btn_premier_01" />
                    </li>
                  </ul>
                </div>}

              </ul>
            </div>

          </div>
        </div>
        <input type="hidden" name="IsSeatBooking" id="IsSeatBooking" className="ng-pristine ng-untouched ng-valid ng-empty" />
        <input type="hidden" name="EventId" id="EventId" className="ng-pristine ng-untouched ng-valid ng-empty" />
        <input type="hidden" name="TicketId" id="TicketId" className="ng-pristine ng-untouched ng-valid ng-not-empty" />
        <input type="hidden" name="NonSeatingTicketId" id="NonSeatingTicketId" className="ng-pristine ng-untouched ng-valid ng-empty" />
        <input type="hidden" name="eventBookingType" id="eventBookingType" />
      </div>
      {/* Table view content end */}
    </>
  )



}