// ** create-user.component.js ** //
import React, { Component, useEffect, useRef } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Cookies } from 'react-cookie'
import { Modal } from 'react-responsive-modal';




export default function ContactUs() {

    // popup model
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    let cookies = new Cookies();
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const nameRegExp = /[A-Za-z]/
    const nameRegEx = /[A-Za-z]{3}/

    const validationSchema = Yup.object().shape({

        firstname: Yup.string()
            .required('Firstname is required')
            .matches(nameRegExp, 'Only alphabetic characters is allowed')
            .matches(nameRegEx, '  Length should be at least 3'),
        lastname: Yup.string()
            .required('Lastname is required')
            .matches(nameRegExp, 'Only alphabetic characters is allowed')
            .matches(nameRegEx, 'Length should be at least 3'),
        mobile: Yup.string()
            .required('Phone number is required')

            .matches(phoneRegExp, 'Only number is required')
        ,
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
    });

    let UserId = cookies.get('id');

    useEffect(() => {

    }, [open])



    // let UserName = secureLocalStorage.getItem('name');
    // let Email_id = secureLocalStorage.getItem('email');

    let UserName = cookies.get('name');
    let Email_id = cookies.get('email');

    const formOptions = { mode: 'onChange', resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const [data, setData] = useState()
    function onSubmit(val) {


        const body = new FormData();

        body.append("firstname", val.firstname);
        body.append("lastname", val.lastname);
        body.append("mobile_number", val.mobile);
        body.append("email", val.email);
        body.append("message", val.message);






        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',


        }
        axios.post(`https://backoffice.theticketcompany.com.au/api/contactus`, body, {
            headers: headers
        })
            .then((res) => {

                setData(res.data.message);
                setOpen(true)

            }).catch((error) => {
                // console.log(error)
            });



    }

    return (
        <>
            <main>


                <Modal open={open} onClose={onCloseModal} center>
                    <h4 className='htext'> {data}</h4>
                    <br></br>
                    <Link to="/" type="submit" className="ok">ok</Link>
                </Modal>

                <section className="gt__signup-sec gt__user-sec gt__bg-image gt__bg-image1">


                    <h1 style={{ textAlign: 'center' }} className="contacts">Contact Us</h1>

                    <div className="gt__signin-wrap gt__user-wrap padding">
                        <div className="gt__signin-inner gt__user-inner">
                            <div className="gt__form-block">
                                <h4 className="gt__user-title txt-center"><span>Contact Details</span></h4>
                                <form method="post" name="userRegistrationForm" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" >
                                    <div className="gt__form-grp gt__uname d-flex d-flex-wrap justify-content-between align-items-center">
                                        <div className="gt__fname-block">
                                            <label htmlFor="fname">First Name</label>
                                            <input type="text" name="firstname" {...register('firstname')} />
                                            <div className="invalid-feedback">{errors.firstname?.message}</div>
                                        </div>
                                        <div className="gt__fname-block">
                                            <label htmlFor="lname">Last Name</label>
                                            <input type="text" name="lastname" {...register('lastname')} />
                                            <div className="invalid-feedback">{errors.lastname?.message}</div>
                                        </div>
                                        <div className="gt__fname-block margin">
                                            <label htmlFor="mobile">Phone Number</label>
                                            <input type="text" name="mobile" {...register('mobile')} />
                                            <div className="invalid-feedback">{errors.mobile?.message}</div>

                                        </div>
                                        <div className="gt__fname-block margin">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" name="email" {...register('email')} />
                                            <div className="invalid-feedback">{errors.email?.message}</div>

                                        </div>
                                        <div className="gt__fname-block margin">
                                            <label htmlFor="message">Message</label>
                                            <textarea name="message" {...register('message')} style={{ width: '540px' }} />
                                            <div className="invalid-feedback">{errors.message?.message}</div>

                                        </div>
                                    </div>




                                    <div className=" gt__form-btn txt-center margin">
                                        <button type="submit" className="gt__btn">Submit</button>
                                    </div>

                                </form></div>
                        </div>
                    </div>

                    <section style={{ marginTop: '102px' }}>
                        <div className='info'>
                            <div className='office'>
                                <h1>Our Office</h1>
                            </div>
                            <div className='address cinfobox'>

                                <h3>Registered Address</h3>
                                <p><span>Aayu Corp Pty Ltd T/As TheTicketCompany.</span>
                                    <span>ABN: 29 657 354 624</span>
                                </p>

                            </div>
                            <div className='phone cinfobox'>
                                <h3>Phone:</h3>

                                <span><a href='tel: +61 403479961'> +61 403479961 </a> </span>
                            </div>
                            <div className='web cinfobox'>
                                <h3>Web:</h3>

                                <a href="https://www.theticketcompany.com.au" target="_blank">www.theticketcompany.com.au</a>
                            </div>
                            <div className='email cinfobox'>
                                <h3>Email: </h3>
                                <a href='mailto:support@theticketcompany.com.au'>support@theticketcompany.com.au</a>
                            </div>

                        </div>

                    </section>
                </section>




            </main>
        </>
    )
}