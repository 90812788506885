import React, { createRef, useEffect, useRef, useState } from 'react'
import Leftarrow from '../images/leftarrow-white.svg';
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Cookies } from 'react-cookie'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import secureLocalStorage from "react-secure-storage";
import ExpiredStorage from "expired-storage";


export default function Seating() {




    const [refreshData, setRefreshData] = useState(false);

    // Model Popup
    const [open, setOpen] = useState(false);
    const [msg, setmsg] = useState("");

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => {
        setOpen(false);
        setRefreshData(true);

    }
    const [open1, setOpen1] = useState(false);


    const onOpenModal1 = () => setOpen1(true);
    const onCloseModal1 = () => {
        setOpen1(false);


    }


    // const [data, setData] = useState();
    let place_id = localStorage.getItem('place_id');
    let event_name = localStorage.getItem('event_name');
    let event_date = localStorage.getItem('event_date');
    let place_name = localStorage.getItem('place_name');
    let event_id = localStorage.getItem('events_id');
    // let UserId = localStorage.getItem('id');

    const cookies = new Cookies();
    let UserId = cookies.get('id');



    // let Dome_Id = secureLocalStorage.getItem('dome_id');
    const [seat_infos, setData] = useState();
    const [seatNo, setSeatNo] = useState([]);
    const headers = {
        'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',
    }

    const getdomedetails = () => {
        axios.get(`https://backoffice.theticketcompany.com.au/api/getdomedetails?placeId=${place_id}&eventId=${event_id}`, {
            headers: headers
        }).then((result) => {

            // console.log(result.data.response_data.seat_infos);
            setData(result.data.response_data.seat_infos);
            setCurrent(result.data.response_data.seat_infos[0].title);

        }).catch((error) => {
            console.log(error);
        });

    };
    useEffect(() => {
        getdomedetails();
    }, [refreshData])

    useEffect(() => {
        getdomedetails();
    }, []);
    // console.log(seat_infos);

    // Select seat code start
    const [current, setCurrent] = useState();

    const [isActive, setIsActive] = useState(false);
    const [seatNo1, setSeatNo1] = useState({});
    const [activeSeats, setActiveSeats] = useState({});

    const cols = [];
    const cols1 = [];
    const [price, setPrice] = useState("");
    const [obj, setobj] = useState(0);
    // console.log(obj);
    // console.log(activeSeats)

    const AddSeat = (id, title, dome_price_id) => {
        setobj(AdCount)

        if (activeSeats[dome_price_id]) {

            setActiveSeats({ ...activeSeats, [dome_price_id]: false })
            setobj(obj - 1)

        } else {

            if (obj < 9) {
                setActiveSeats({ ...activeSeats, [dome_price_id]: true })
            }
            else {
                setOpen1(true)
            }
        }

        const getstatedata = seat_infos.find(seat => seat.title === title).info;


        cols.push(getstatedata.filter((seat) => seat.id == id)[0].seat_number);
        setPrice(getstatedata.filter((seat) => seat.id == id)[0].seat_price);


        cols1.push(getstatedata.filter((seat) => seat.id == id)[0].dome_list_id);
        // console.log(cols1);
        if (seatNo1[cols]) {

            setSeatNo1({ ...seatNo1, [cols]: false })
        } else {
            setSeatNo1({ ...seatNo1, [cols]: true })

        }

        setSeatNo([...seatNo, cols1])


    }



    // Storing Seat ID in Array And setting Seat Count

    const arrOfObj1 = Object.values(activeSeats);
    const arrOfObj2 = Object.keys(activeSeats);
    const arrOfObj3 = Object.values(seatNo1);
    const arrOfObj4 = Object.keys(seatNo1);

    const temp = [];
    const seatid = [];

    for (let i = 0; i <= arrOfObj1.length; i++) {
        if (arrOfObj1[i] == true) {
            // console.log("hello");
            seatid.push(arrOfObj2[i]);
        }
    }

    for (let i = 0; i <= arrOfObj3.length; i++) {
        if (arrOfObj3[i] == true) {
            temp.push(arrOfObj4[i]);
        }
    }
    // console.log(temp)


    // console.log(seatid);
    let AdCount = seatid.length;


    function test(e) {
        const titles = e.target.id;


        setCurrent(titles)
        setActiveSeats("")
        setobj(0)

    }
    function unset(e) {

        setCurrent("")

    }
    // console.log(seatid);
    // END

    const navigate = useNavigate();
    const [getapi, setapi] = useState([]);

    const clickButton = () => {
        //  setRefreshData(true)

        // Page position scroll code start
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        // Page position scroll code end

        seatNo.length = 1;

        // console.log(seatNo1);
        localStorage.setItem("AdCount", AdCount);
        localStorage.setItem("seatid", seatid);
        // secureLocalStorage.setItem("seatname", temp);
        localStorage.setItem("seatname1", seatNo);
        // secureLocalStorage.setItem("amount", totalamt);


        cookies.set("AdCount", AdCount);
        cookies.set("seatid", seatid);
        cookies.set("seatname", temp);
        //cookies.set("seatname1", seatNo);
        cookies.set("amount", totalamt);

        const body = new FormData();

        body.append("dome_price_id", seatid);
        body.append("in_progress", "1");
        body.append("event_id", event_id);
        body.append("place_id", place_id);
        body.append("user_id", UserId);
        body.append("dome_list_id", seatNo);



        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',

        }
        if (UserId) {


            axios.post('https://backoffice.theticketcompany.com.au/api/booking-ticket', body, {
                headers: headers
            })
                .then((res) => {
                    if (res.status !== false) {
                        setapi(res.data.ticketBooking_id);

                        // console.log(res.data.ticketBooking_id);
                        const value = [res.data.ticketBooking_id]

                        localStorage.setItem("ticketBooking_id", value);

                        cookies.set("ticketBooking_id", value);
                        const expiredStorage = new ExpiredStorage();
                        expiredStorage.setItem("test", "foobar", 900);

                        // console.log(value);
                        if (AdCount >= 1) {
                            navigate('/auditoriumusers');



                        }


                    }
                    else {
                        // console.log(res.message)

                    }

                }).catch((error) => {
                    console.log(error)
                    console.log(error.response.data.message)
                    setmsg(error.response.data.responses)
                    onOpenModal();
                });

        }
        else {

            navigate('/login');
        }


    }



    const navigates = useNavigate();
    const totalamt = price * AdCount;



    return (
        <>
            {seat_infos && <main>

                <Modal open={open} showCloseIcon={false} center>
                    <p className='htext'>Please try another seat, Someone else is booking this {msg} seat at present now.</p>
                    <br></br>
                    <button onClick={onCloseModal} type="submit" className="ok">ok</button>
                </Modal>

                <Modal open={open1} showCloseIcon={false} center>
                    <p className='htext'>You are only allowed to book 10 ticket(s) in one transaction.</p>
                    <br></br>
                    <button onClick={onCloseModal1} type="submit" className="ok">ok</button>
                </Modal>


                <section className="gt__user-sec ticketsec ticketsec2">
                    <div className="ticketinfo" >
                        <img onClick={() => navigates(-1)} src={Leftarrow}></img>
                        <h2>{event_name}</h2>
                        <p className="venuedate venueinfo">{event_date}<span className="venueinfo">{place_name}</span></p>
                    </div>
                </section>
                <section className="gt__user-sec gt__bg-image seatlayout eventabout">
                    <div className='tableview'>
                        {seat_infos ? seat_infos.map((seat_infos, i) => {
                            const dome = seat_infos;
                            return (<>


                                {dome.title == current && <div key={i} className="tableselected" >{dome.title}<button className='selecttable' onClick={(e) => (test(e))} id={dome.title}>select</button></div>
                                    || <div className="">{dome.title}<button className='unselecttable' onClick={(e) => (test(e))} id={dome.title}>select</button></div>
                                }


                            </>
                            )
                        }) : <h3>Data Not Found</h3>}
                    </div>
                    <div className='rightview'>
                        {seat_infos ? seat_infos.map((seat_infos, index) => {
                            const dome = seat_infos;
                            return (
                                <>
                                    <div className='panelcontainer'>
                                        <div className="seatlayoutbox seatlayoutbox1 spanel">
                                            <div className="seathead"></div>
                                            {dome.title == current && <div>
                                                <h3 key={index[0]}>{dome.title}</h3>
                                                <div className="seatarrangement">
                                                    <div className="seatrow seatrow1">

                                                        {(() => {
                                                            const userdata = [];
                                                            const seats = dome.info;
                                                            if (seats != "") {
                                                                for (let i = 1; i <= dome.max_row; i++) {

                                                                    const rows = seats.filter((seat) => seat.drome_row == i);
                                                                    // console.log(rows);
                                                                    userdata.push(
                                                                        <>
                                                                            <ul>

                                                                                {(() => {
                                                                                    const userdata = [];

                                                                                    for (let j = 1; j <= dome.max_column; j++) {
                                                                                        const cols = rows.filter((seat) => seat.drome_column == j && seat.drome_row == i);

                                                                                        if (cols == '') {
                                                                                            continue;
                                                                                        }
                                                                                        const col = cols[0];
                                                                                        const type = col.seat_type;
                                                                                        const status = col.seat_status;
                                                                                        const inprogress = col.booking_status;


                                                                                        if (type == "seat") {
                                                                                            if (status == "bookable" && inprogress == null) {
                                                                                                userdata.push(
                                                                                                    <>
                                                                                                        <li className={activeSeats[col.dome_price_id] ? 'selected' : ''}
                                                                                                            onClick={() => AddSeat(col.id, dome.title, col.dome_price_id)}
                                                                                                            id={col.id}

                                                                                                        >

                                                                                                            {col.seat_number}
                                                                                                        </li>
                                                                                                    </>
                                                                                                );
                                                                                            }
                                                                                            else {
                                                                                                if (inprogress == '0') {
                                                                                                    userdata.push(
                                                                                                        <>
                                                                                                            <li className="inprogress">

                                                                                                                {col.seat_number}
                                                                                                            </li>
                                                                                                        </>
                                                                                                    );
                                                                                                }
                                                                                                else {
                                                                                                    userdata.push(
                                                                                                        <>
                                                                                                            <li className="occupied">

                                                                                                                {col.seat_number}
                                                                                                            </li>
                                                                                                        </>
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            userdata.push(
                                                                                                <>
                                                                                                    <li className='blank'></li>
                                                                                                </>
                                                                                            );
                                                                                        }

                                                                                    }
                                                                                    return userdata;
                                                                                })()}

                                                                            </ul>
                                                                        </>
                                                                    );
                                                                }
                                                            }
                                                            else {
                                                                userdata.push(
                                                                    <>
                                                                        <h1>Dome data not found</h1>
                                                                    </>
                                                                );
                                                            }

                                                            return userdata;
                                                        })()}
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>



                                </>

                            )
                        }) : <h3>Data Not Found</h3>}</div>




                </section>
                {/* <!--end seatlayout sec--> */}

                {/* <!--start seatlayout sec--> */}
                <section className="gt__user-sec bottomfixd proceedbtnsec proceedbtnsec2">
                    <div className="seatinfo proceedbtn proceedbtn2">
                        <ul>
                            <li>
                                <div className="infobox infobox4"></div>
                                <h4>In Progress</h4>
                            </li>
                            <li>
                                <div className="infobox infobox1"></div>
                                <h4>Sold</h4>
                            </li>
                            <li>
                                <div className="infobox infobox2"></div>
                                <h4>Available</h4>
                            </li>
                            <li>
                                <div className="infobox infobox3"></div>
                                <h4>Selected</h4>
                            </li>
                        </ul>
                        <h3> AUD {totalamt}<span> Ticket {AdCount}<span /></span></h3>
                        <button onClick={clickButton} >Proceed</button>
                    </div>

                </section>

                {/* <!--end seatlayout sec--> */}
            </main> || <h3><div className="loader"></div></h3>}
        </>
    )
};


