// ** create-user.component.js ** //
import React, { Component, useEffect, useRef } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Cookies } from 'react-cookie'
import { Modal } from 'react-responsive-modal';




export default function UserDetails() {

    // popup model
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    let cookies = new Cookies();
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const nameRegExp = /[A-Za-z]/
    const nameRegEx = /[A-Za-z]{3}/

    const validationSchema = Yup.object().shape({


        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
    });

    let UserId = cookies.get('id');
    const [load, setLoad] = useState(false);
    const onLoadModal = () => setLoad(true);



    const [datas, setDatas] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');


    // let UserName = secureLocalStorage.getItem('name');
    // let Email_id = secureLocalStorage.getItem('email');

    let UserName = cookies.get('name');
    let Email_id = cookies.get('email');

    const formOptions = { mode: 'onChange', resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const [data, setData] = useState()
    const navigate = useNavigate();

    function onSubmit(val) {
        // console.log(val);
        setLoad(true)

        const body = new FormData();

        body.append("email", val.email);

        const headers = {
            'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',


        }
        axios.post(`https://backoffice.theticketcompany.com.au/api/forget-password`, body, {
            headers: headers
        })
            .then((res) => {

                if (res.data.status == 1) {
                    setLoad(false)

                    // console.log(res.data.message);
                    setData(res.data.message);
                    setOpen(true)
                } else {
                    setLoad(true)
                    navigate('/checkemail');

                }

            }).catch((error) => {
                // console.log(error)
            });



    }

    return (
        <>
            <main>
                <Modal open={open} onClose={onCloseModal} center>
                    <h4 className='htext'> {data}</h4>
                    <br></br>
                    <button onClick={onCloseModal} type="submit" className="ok">ok</button>
                </Modal>

                <Modal open={load} showCloseIcon={false} center>
                    <h3><div className="loader1"></div></h3>
                </Modal>


                <section className="gt__signup-sec gt__user-sec gt__bg-image gt__bg-image1">

                    <div className="gt__signin-wrap gt__user-wrap padding">

                        <div className="gt__signin-inner gt__user-inner">

                            <div className="gt__form-block">
                                <h4 className="gt__user-title txt-center"><span>Forgot Password</span></h4>
                                <form method="post" name="userRegistrationForm" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" >

                                    <div className="gt__fname-block margin">
                                        <label htmlFor="email"> Enter Your Email</label>
                                        <br></br>
                                        <br></br>

                                        <input type="email" name="email" {...register('email')} />
                                        <div className="invalid-feedback">{errors.email?.message}</div>

                                    </div>

                                    <div className=" gt__form-btn txt-center margin">

                                        <button type="submit" className="gt__btn">Submit</button>
                                    </div>

                                </form></div>
                        </div>
                    </div>
                </section>

            </main>
        </>
    )
}