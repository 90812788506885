import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Phone from '../images/phn.svg';
import EmailIcon from '../images/email-icon.svg';
import Logo from '../images/The_ticket_company_logo.svg';
import Mssage from '../images/mssage.svg';
import WhatsApp from '../images/whatsapp.svg';
import Twitter from '../images/twitter.svg';
import Facebook from '../images/facebook.svg';
import Snapchat from '../images/snapchat.svg';
import Visa from '../images/visa.svg';
import Mastercard from '../images/mastercard.svg';
import Americanexpress from '../images/americanexpress.svg';
import Unionpay from '../images/unionpay.svg';
import Lock from '../images/lock.svg';
import Instagram from '../images/instagram.svg';
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { Helmet } from "react-helmet";

export default function Footer() {
	// Page position scroll code start
	const handleScroll = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}
	// Page position scroll code end



	// email,contact,copyright API calling start
	const [data, setData] = useState('');
	const headers = {
		'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',
	}
	const getSetting = () => {
		axios.get('https://backoffice.theticketcompany.com.au/api/settings',{
			headers: headers
		}).then((response) => {

			// console.log(response.data.response_data);
			setData(response.data.response_data);

		}).catch((error) => {
			// console.log(error);
		});
	};
	useEffect(() => {
		getSetting();

	}, []);

	// email,contact,copyright API calling end



	return (
		<div>
			{/* start footer */}
			<footer>
				<div className="gt__footer-sec">
					<div className="gt__call-sec txt-center">
						<h4>Need Assistance?</h4>
						<div className="gt__number-sec">
							<a href="tel:+61403479961">
								<img src={Phone} alt="phone" width={40} height={40} loading="lazy" />
								<span>+61 403479961</span>
							</a> | <a href="mailto:support@theticketcompany.com.au">
								<img src={EmailIcon} alt="email" width={40} height={40} loading="lazy" />
								<span>support@theticketcompany.com.au</span>
							</a>

						</div>
					</div>
					<div className="gt__footer-wrap d-flex d-flex-wrap">
						<div className="gt__footer-left gt__full-width">
							<div className="gt__footer-logo">
								<NavLink to="/" onClick={handleScroll} className="gt__header-logo"><img src={Logo} alt="The_ticket_company" loading="lazy" /></NavLink>
							</div>
							<div className="gt__footer-social">
								{/* <h3>The Heart Of Touch</h3> */}
								<h3>Connect with Us</h3>
								<div className="gt__social-img d-flex d-flex-wrap align-items-center">

									{/* <div className="gt__mssage">
										<a href="#">
											<img src={Mssage} alt="mssage" width={12} height={12} />
										</a>
										<span className="gt__text">Message</span>
									</div>
									<div className="gt__whatspp">
										<a href="#"><img src={WhatsApp} alt="whatsapp" width={15} height={15} /></a>
										<span className="gt__text">WhatsApp</span>
									</div> */}
									<div className="gt_twitter">
										<a href="https://twitter.com/TicketComapnyAU" target="_blank"><img src={Twitter} alt="twitter" width={13} height={11} loading="lazy" /></a>
										<span className="gt__text">Twitter</span>
									</div>
									<div className="gt_facebook">
										<a href="https://www.facebook.com/people/The-Ticket-Company/100088406403132/" target="_blank"><img src={Facebook} alt="facebook" width={8} height={16} loading="lazy" /></a>
										<span className="gt__text">Facebook</span>
									</div>
									<div className="gt_instragram">
										<a href="https://www.instagram.com/theticketcompany_aus/" target="_blank"><img src={Instagram} alt="instragram" width={15} height={15} loading="lazy" /></a>
										<span className="gt__text"> Instagram</span>
									</div>
								</div>
							</div>
						</div>
						<div className="gt__footer-right gt__full-width">
							<div className="gt__footer-rightwrap  d-flex d-flex-wrap justify-content-between">
								<div className="gt__footer-menu gt__full-width">
									<h3>Overview</h3>
									<ul>
										<li><Link to="/about-us" onClick={handleScroll}>About us</Link></li>
										{/* <li><Link to="/past-events" onClick={handleScroll}>Past Events</Link></li> */}
										<li><Link to="/faq" onClick={handleScroll}>Faq's</Link></li>
										{/* <li><Link to="/collection-statement" onClick={handleScroll}>Collection Statement</Link></li> */}
										<li><Link to="/contact-us" onClick={handleScroll}>Contact us</Link></li>
										{/* <li><Link to="/career" onClick={handleScroll}>Career</Link></li> */}

									</ul>
								</div>
								<div className="gt__footer-menu gt__full-width">
									<h3>Legal</h3>
									<ul>
										<li><NavLink onClick={handleScroll} to={"/privacy-policy"} >Privacy Policy</NavLink></li>
										<li><NavLink onClick={handleScroll} to={"/terms-condition"} >Terms &amp; Condition</NavLink></li>
										<li><NavLink onClick={handleScroll} to={"/payment-return-policy"} >Payment &amp; Return Policy</NavLink></li>
									</ul>
								</div>
								<div className="gt__footer-payment gt__full-width">
									<div className="gt__footer-paymentinner">
										<h3 className="gt__title txt-center"><span>We are accepting these cards</span></h3>
										<div className="gt__payment-logo">
											<img src={Visa} alt="visa" width="68.15" height="36.4" loading="lazy" />
											<img src={Mastercard} alt="mastercard" width="68.15" height="36.4" loading="lazy" />
											<img src={Americanexpress} alt="americanexpress" width="68.15" height="36.4" loading="lazy" />
											<img src={Unionpay} alt="unionpay" width="68.15" height="36.4" loading="lazy" />
										</div>
										<h3 className="gt__bootom-title txt-center">
											<span className="gt__lock"><img src={Lock} alt="The_Ticket_Company" width={15} height={15} loading="lazy" /></span>
											<span className="gt__lock-text">SSL Secured</span>
										</h3>
									</div>
								</div>
								<div className="gt__copywriti txt-center gt__full-width">
									<p>Copyright 2023 © The Ticket Company.</p>
								</div>
							</div>
						</div>
					</div>

				</div>
				{/* <Helmet>
					<script type="text/javascript" src="https://www.shakebug.com/assets/js/shakebug-min-1.0.js" id="apikey"
						isReportingIconVisible="true" apikey="Uii2UwE7DRtkSGQoqE6wJD72O6MrPT" ></script>
				</Helmet> */}
			</footer>
			{/* end footer */}

		</div>
	)
}