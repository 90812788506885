import React, { createRef, useEffect, useRef, useState } from 'react'
import Leftarrow from '../images/leftarrow-white.svg';
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Cookies } from 'react-cookie'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import secureLocalStorage from "react-secure-storage";
import ExpiredStorage from "expired-storage";


export default function Seating() {


    let combo_event_discount = localStorage.getItem('combo_event_discount');

    const [refreshData, setRefreshData] = useState(false);

    // Model Popup
    const [open, setOpen] = useState(false);
    const [msg, setmsg] = useState("");

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => {
        setOpen(false);
        setRefreshData(true);

    }
    const [open1, setOpen1] = useState(false);


    const onOpenModal1 = () => setOpen1(true);
    const onCloseModal1 = () => {
        setOpen1(false);


    }


    // const [data, setData] = useState();
    let place_id = localStorage.getItem('place_id');
    let event_name = localStorage.getItem('event_name');
    let combo_event_id = localStorage.getItem('combo_event_id');
    let event_dates = localStorage.getItem('event_date');
    let place_name = localStorage.getItem('place_name');
    let event_id = localStorage.getItem('events_id');
    let event_date = localStorage.getItem('events_date');

    event_id = event_id.split(',');
    event_name = event_name.split(',');
    event_date = event_date.split(',');
    place_name = place_name.split(',');

    // let UserId = secureLocalStorage.getItem('id');

    const cookies = new Cookies();
    let UserId = cookies.get('id');



    // let Dome_Id = secureLocalStorage.getItem('dome_id');
    const [current, setCurrent] = useState([]);

    const [isActive, setIsActive] = useState([]);
    const [seatNo1, setSeatNo1] = useState({});
    const [activeSeats, setActiveSeats] = useState([]);

    const [seat_infos, setData] = useState();
    const [seat_info, SetDome_info] = useState([]);
    const [seat_infoss, SetDome_infos] = useState([]);
    const [seat_EventId, setCurrentEventId] = useState();
    const [seatNo, setSeatNo] = useState([]);

    // let temdome = [];
    let temdome = new Array(event_id.length);

    const headers = {
        'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',
    }

    const getdomedetails = () => {

        // for (let indexId = 0; indexId < event_id.length; indexId++) {

        axios.get(`https://backoffice.theticketcompany.com.au/api/comboeventdomedetails?placeId=${place_id}&eventId=${event_id}`, {
            headers: headers
        }).then((result) => {
            setData(result.data.response_data);
            // console.log('result.data.response_data.length', result.data.response_data.length)
            for (let index = 0; index < result.data.response_data.length; index++) {
                 SetDome_infos(result.data.response_data[index].dome_info)
                for (let i = 0; i < result.data.response_data[index].dome_info.length; i++) {

                    setCurrent(result.data.response_data[i].dome_info[i].title);
                    setCurrentEventId(result.data.response_data[i].dome_info[i].event_id);

                }
                temdome[index] = result.data.response_data[index].dome_info
                // temdome.push(result.data.response_data[index].dome_info);
                // console.log('temdome', result.data.response_data[index].dome_info[index].title)

            }
            SetDome_info(temdome)
        }).catch((error) => {
             console.log(error);
        });


        // }

        // console.log('seat_infos', seat_infos)

    };



    useEffect(() => {
        getdomedetails();
    }, [refreshData])

    // useEffect(() => {
    //     getdomedetails();
    // }, []);

    // Select seat code start

    const cols = [];
    let cols1 = [];
    let temps = [];
    const [price, setPrice] = useState(0);
    const [obj, setobj] = useState(0);
    let newitemArray = [];
    let idLength = [];
    let dome_list = [];
    const [arrayvalues, setarrayvalues] = useState([]);
    const [idlength, setidlength] = useState([]);

    const AddSeat = (dome_price_id, id, title) => {
        setobj(AdCount)

        if (activeSeats[dome_price_id]) {

            setActiveSeats({ ...activeSeats, [dome_price_id]: false })
            setobj(obj - 1)

        } else {

            if (obj < 9) {
                setActiveSeats({ ...activeSeats, [dome_price_id]: true })
            }
            else {
                setOpen1(true)
            }
        }
        let getstatedata = [];

        for (let index = 0; index < seat_info.length; index++) {
            // console.log('seat_info', seat_info)

            getstatedata = seat_info[index].find(seat => seat.title === title).info;


            //    console.log('getstatedata', getstatedata)
            for (let indexs = 0; indexs < getstatedata.length; indexs++) {

                if (getstatedata[indexs].dome_price_id == dome_price_id && getstatedata[indexs].event_id == event_id[index]) {

                    // console.log('dome_list_id', getstatedata[indexs].dome_list_id)
                    dome_list = getstatedata[indexs].dome_list_id;
                    var dome_id = getstatedata[indexs].dome_price_id;

                    newitemArray = [...isActive, [getstatedata[indexs].seat_number, getstatedata[indexs].event_id, getstatedata[indexs].dome_price_id, getstatedata[indexs].dome_list_id]];

                    cols1 = newitemArray.map(subarray => subarray[3]);
                    // console.log('sub_arr', sub_arr)

                    setPrice(getstatedata[indexs].seat_price + price);

                    if (isActive.length > 0) {
                        const index = isActive.findIndex(arr => arr[2] === dome_id);
                        if (index > -1) {
                            setPrice(price - getstatedata[indexs].seat_price)
                            const filteredArray = newitemArray.filter(arr => arr[2] !== dome_id);
                            newitemArray = [...filteredArray];
                        }
                    }


                    setIsActive(newitemArray);
                    // console.log('newitemArray', newitemArray)

                    for (let i = 0; i < event_id.length; i++) {
                        // console.log(value);
                        let arr = newitemArray.filter(arr => arr[1] == event_id[i]);
                        let sub_arr = arr.map(subarray => subarray[0]);
                        const length = newitemArray.filter(arr => arr[1] == event_id[i]).length;
                        idLength.push([event_name[i], event_date[i], place_name[i], event_id[i], length, sub_arr]);


                    }

                    const values = idLength.map(subarray => subarray[4]);
                    // console.log("k", idLength);
                    let areValuesSame = values.every(value => value === values[0]);
                    // console.log(typeof(areValuesSame));
                    setarrayvalues(areValuesSame)
                    setidlength(idLength)



                }


            }
        }
        // console.log(isActive)
        // console.log(price)

        if (seatNo1[cols]) {

            setSeatNo1({ ...seatNo1, [cols]: false })
        } else {
            setSeatNo1({ ...seatNo1, [cols]: true })

        }

        setSeatNo([cols1])


    }

    // console.log('seatNo', seatNo)
    // console.log("idLength",idlength);
    localStorage.setItem("selectedseat", JSON.stringify(idlength));

    // console.log('testdata', arrayvalues)

    // console.log(areValuesSame);


    // Storing Seat ID in Array And setting Seat Count

    const arrOfObj1 = Object.values(activeSeats);
    const arrOfObj2 = Object.keys(activeSeats);
    const arrOfObj3 = Object.values(seatNo1);
    const arrOfObj4 = Object.keys(seatNo1);

    const temp = [];
    const seatid = [];

    for (let i = 0; i <= arrOfObj1.length; i++) {
        if (arrOfObj1[i] == true) {
            seatid.push(arrOfObj2[i]);
        }
    }

    for (let i = 0; i <= arrOfObj3.length; i++) {
        if (arrOfObj3[i] == true) {
            temp.push(arrOfObj4[i]);
        }
    }


    let AdCount = seatid.length;


    function test(e) {
        const titles = e.target.id;


        setCurrent(titles)
         setActiveSeats("")
        setobj(0)

    }
    function unset(e) {

        setCurrent("")

    }

    const navigate = useNavigate();
    const [getapi, setapi] = useState([]);



    const clickButton = () => {

        if (arrayvalues == true) {
            //  setRefreshData(true)

            // Page position scroll code start
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

            // Page position scroll code end

            seatNo.length = 1;



            localStorage.setItem("AdCount", AdCount);
            localStorage.setItem("seatid", seatid);
            // secureLocalStorage.setItem("seatname", temp);
            localStorage.setItem("seatname1", seatNo);
            // localStorage.setItem("amount", totalamt);


            cookies.set("AdCount", AdCount);
            cookies.set("seatid", seatid);
            cookies.set("seatname", temp);
            cookies.set("seatname1", seatNo);
            cookies.set("amount", totalamt);

            const body = new FormData();

            body.append("dome_price_id", seatid);
            body.append("in_progress", "1");
            body.append("event_id", event_id);
            body.append("place_id", place_id);
            body.append("user_id", UserId);
            body.append("dome_list_id", seatNo);
            body.append("combo_event_id", combo_event_id);



            const headers = {
                'X_INFINITY': 'zUxKIbHKvUElDJPZKYUh',

            }
            if (UserId) {


                axios.post('https://backoffice.theticketcompany.com.au/api/booking-ticket', body, {
                    headers: headers
                })
                    .then((res) => {
                        if (res.status !== false) {
                            setapi(res.data.ticketBooking_id);

                            const value = [res.data.ticketBooking_id]
                            localStorage.setItem("ticketBooking_id", value);

                            cookies.set("ticketBooking_id", value);
                            const expiredStorage = new ExpiredStorage();
                            expiredStorage.setItem("test", "foobar", 900);

                            if (AdCount >= 1) {
                                navigate('/comboauditoriumusers');

                            }


                        }
                        else {
                            // console.log(res.message)

                        }

                    }).catch((error) => {
                        // console.log(error)
                        // console.log(error.response.data.message)
                        setmsg(error.response.data.responses)
                        onOpenModal();
                    });

            }
            else {

                navigate('/login');
            }
        }
        else {
            // console.log("please select number of seat in all event");
        }



    }



    const [final_amount, SetFinal_amount] = useState(0);

    const navigates = useNavigate();

    // const totalamt = price;
    let totalamt;
    let withoutdescount;
    // console.log(price);

    if (arrayvalues == true) {
        if (combo_event_discount != null) {
            let discountedAmt = Math.ceil(price * (combo_event_discount / 100));
            // console.log("discountedAmt",discountedAmt);
            withoutdescount = price;
            totalamt = price - discountedAmt
            // SetFinal_amount(final)
        }
    } else {
        totalamt = price;

    }


    //    console.log("final",final);
    // SetFinal_amount(final);
    // console.log('totalamt', totalamt);
    // console.log('AdCount', AdCount)


    return (
        <>
            {seat_info && <main>

                <Modal open={open} showCloseIcon={false} center>
                    <p className='htext'>Please try another seat, Someone else is booking this {msg} seat at present now.</p>
                    <br></br>
                    <button onClick={onCloseModal} type="submit" className="ok">ok</button>
                </Modal>

                <Modal open={open1} showCloseIcon={false} center>
                    <p className='htext'>You are only allowed to book 10 ticket(s) in one transaction.</p>
                    <br></br>
                    <button onClick={onCloseModal1} type="submit" className="ok">ok</button>
                </Modal>
                {/* <Modal open={open2} showCloseIcon={false} center>
                    <p className='htext'>please select same number of seats of all events.</p>
                    <br></br>
                    <button onClick={onCloseModal1} type="submit" className="ok">ok</button>
                </Modal> */}

                {(() => {
                    const userdata = [];
                    for (let indexID = 0; indexID < event_id.length; indexID++) {

                        userdata.push(
                            <>

                                <section className="gt__user-sec ticketsec ticketsec2 ticketsec3">
                                    <div className="ticketinfo" >
                                        <img onClick={() => navigates(-1)} src={Leftarrow}></img>
                                        <h2>{event_name[indexID]}</h2>
                                        <p className="venuedate venueinfo">{event_date[indexID]}<span className="venueinfo">{place_name[indexID]}</span></p>
                                    </div>
                                </section>
                                <section className="gt__user-sec gt__bg-image seatlayout eventabout">
                                    <div className='tableview'>

                                        {seat_info  ? seat_info.map((seat_info, indexdome1) => {
                                            const dome1 = seat_info;
                                            return (
                                                dome1.map((dome, indexdome) => {
                                                    if (event_id[indexID] == dome.event_id) {

                                                        return (<>

                                                            {dome.title == current && <div key={indexdome} className="tableselected" id={indexdome}>{dome.title}<button className='selecttable' onClick={(e) => (test(e))} id={dome.title}>Select</button></div>
                                                                || <div className="" >{dome.title}<button className='unselecttable' onClick={(e) => (test(e))} id={dome.title}>Select</button></div>
                                                            }


                                                        </>

                                                        )
                                                    }



                                                }))
                                        }) : <h3>Data Not Found</h3>}
                                    </div>
                                    <div className='rightview'>
                                        {seat_info ? seat_info.map((seat_info, index1) => {
                                            const dome = seat_info;
                                            return (
                                                dome.map((dome, index) => {
                                                    if (event_id[indexID] == dome.event_id) {
                                                        return (

                                                            <>
                                                                <div className='panelcontainer'>
                                                                    <div className="seatlayoutbox seatlayoutbox1 spanel">
                                                                        <div className="seathead"></div>
                                                                        {dome.title == current && <div>
                                                                            <h3 key={index[0]}>{dome.title}</h3>
                                                                            <div className="seatarrangement">
                                                                                <div className="seatrow seatrow1">

                                                                                    {(() => {
                                                                                        const userdata = [];
                                                                                        const seats = dome.info;

                                                                                        if (seats != "") {

                                                                                            for (let i = 1; i <= dome.max_row; i++) {

                                                                                                const rows = seats.filter((seat) => seat.drome_row == i);

                                                                                                userdata.push(
                                                                                                    <>

                                                                                                        <ul>

                                                                                                            {(() => {
                                                                                                                const userdata = [];

                                                                                                                for (let j = 1; j <= dome.max_column; j++) {
                                                                                                                    const cols = rows.filter((seat) => seat.drome_column == j && seat.drome_row == i);

                                                                                                                    if (cols == '') {
                                                                                                                        continue;
                                                                                                                    }
                                                                                                                    const col = cols[0];
                                                                                                                    const type = col.seat_type;
                                                                                                                    const status = col.seat_status;
                                                                                                                    const inprogress = col.booking_status;


                                                                                                                    if (type == "seat") {
                                                                                                                        if (status == "bookable" && inprogress == null) {
                                                                                                                            userdata.push(
                                                                                                                                <>
                                                                                                                                    <li className={activeSeats[col.dome_price_id] ? 'selected' : ''}
                                                                                                                                        onClick={() => AddSeat(col.dome_price_id, col.id, dome.title)}
                                                                                                                                        id={col.id + "_" + event_id[indexID]}

                                                                                                                                    >

                                                                                                                                        {col.seat_number}
                                                                                                                                    </li>
                                                                                                                                </>
                                                                                                                            );
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            if (inprogress == '0') {
                                                                                                                                userdata.push(
                                                                                                                                    <>
                                                                                                                                        <li className="inprogress">

                                                                                                                                            {col.seat_number}
                                                                                                                                        </li>
                                                                                                                                    </>
                                                                                                                                );
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                userdata.push(
                                                                                                                                    <>
                                                                                                                                        <li className="occupied">

                                                                                                                                            {col.seat_number}
                                                                                                                                        </li>
                                                                                                                                    </>
                                                                                                                                );
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        userdata.push(
                                                                                                                            <>
                                                                                                                                <li className='blank'></li>
                                                                                                                            </>
                                                                                                                        );
                                                                                                                    }

                                                                                                                }
                                                                                                                return userdata;
                                                                                                            })()}
                                                                                                        </ul>
                                                                                                    </>
                                                                                                );
                                                                                            }
                                                                                        } else {
                                                                                            userdata.push(
                                                                                                <>
                                                                                                    <h1>Dome data not found</h1>
                                                                                                </>
                                                                                            );
                                                                                        }

                                                                                        return userdata;
                                                                                    })()}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </>

                                                        )
                                                    }
                                                }))
                                        }) : <h3>Data Not Found</h3>}</div>

                                </section>
                                {/* <!--end seatlayout sec--> */}

                                {/* <!--start seatlayout sec--> */}

                            </>);

                    }

                    return userdata;
                })()}


                <section className="gt__user-sec bottomfixd proceedbtnsec proceedbtnsec2">
                    <div className="seatinfo proceedbtn proceedbtn2">
                        <ul>
                            <li>
                                <div className="infobox infobox4"></div>
                                <h4>In Progress</h4>
                            </li>
                            <li>
                                <div className="infobox infobox1"></div>
                                <h4>Sold</h4>
                            </li>
                            <li>
                                <div className="infobox infobox2"></div>
                                <h4>Available</h4>
                            </li>
                            <li>
                                <div className="infobox infobox3"></div>
                                <h4>Selected</h4>
                            </li>
                        </ul>
                        <h3> AUD {arrayvalues == true && <span className='withoutdescount'>{withoutdescount}</span>} {totalamt || "0"}<span> Ticket {AdCount}<span /></span></h3>
                        {arrayvalues == true && <button onClick={clickButton} >Proceed</button> || <button disabled onClick={clickButton} >Proceed</button>}
                    </div>

                </section>
                {/* <!--end seatlayout sec--> */}
            </main> || <h3><div className="loader"></div></h3>}
        </>
    )
};


